import "./components/disclosure-nav.js";
import "./components/details.js";

const queue = new Set();

function addToQueue(entry) {
    let { name, value, delta, attribution, id } = entry;
    queue.add({
        id,
        name,
        value,
        delta,
        articleId: af?.article?.id,
        event: attribution?.event,
        eventTarget: attribution?.eventTarget
    });
}

function flushQueue() {
  if (queue.size > 0) {
    const body = JSON.stringify([...queue]);

    (navigator.sendBeacon && navigator.sendBeacon("/api/analytics/web-vitals", body)) ||
      fetch("/api/analytics/web-vitals", {body, method: 'POST', keepalive: true});

    queue.clear();
  }
}

async function reportWebVitals() {
  let webVitalsPath = "/lib/web-vitals/3.1.1/dist/web-vitals.js";
  let { onLCP, onFID, onFCP, onTTFB, onCLS, onINP } = await import(webVitalsPath);

  onLCP(addToQueue);
  onFID(addToQueue);
  onFCP(addToQueue);
  onTTFB(addToQueue);
  onCLS(addToQueue);
  onINP(addToQueue);

  // Report all available metrics whenever the page is backgrounded or unloaded.
  addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      flushQueue();
    }
  });

  // NOTE: Safari does not reliably fire the `visibilitychange` event when the
  // page is being unloaded. If Safari support is needed, you should also flush
  // the queue in the `pagehide` event.
  addEventListener('pagehide', flushQueue);
}

let rIC = window.requestIdleCallback ?? function(callback) {
  setTimeout(callback, 2000);
}

rIC(reportWebVitals);

document.addEventListener("DOMContentLoaded", () => {
  if (window.refreshFsLightbox) {
    document.querySelectorAll(".gallery > li > a").forEach(a => a.setAttribute("data-fslightbox", ""));
    refreshFsLightbox();
  }
});